
import { Component, Prop, Emit, Watch, Vue } from 'vue-property-decorator';
import store from '@/store';

@Component
export default class Breadcrumb extends Vue {
  private name = 'Breadcrumb';
  get path() {
    return this.$store.getters['breadcrumb/path'];
  }
}


import { Component, Mixins, Prop } from 'vue-property-decorator';
import ViewPage from '@/mixins/Views';
import PageHeader from '@/components/page/PageHeader.vue';
import Breadcrumb from '@/components/navi/Breadcrumb.vue';

@Component({
  components: {
    PageHeader,
    Breadcrumb,
  },
})
export default class LayoutHasPagesTitle extends Mixins(ViewPage) {
  private name = 'LayoutHasPagesTitle';
  @Prop()
  private title1!: string;
  @Prop()
  private title2!: string;
}
